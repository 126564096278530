import PropTypes from 'prop-types';
import React, { Component } from 'react';
import pageConnect from '../../redux/pageConnect';

import Card from '@material-ui/core/Card';

import Header from '../../components/Header';
import Helpers from '../../helpers';

/*
  This page should handle submitting the information for the applicant.
  The page should initially show a loader, and with a success response,
  display the congratulatory message.
  If an error in submission is encountered, the applicant should be 
  redirected to an error page, ideally with instructions to fix any
  errors if possible.
*/

class Finished extends Component {
    handleNavigation() {
    console.error('Navigation triggered from Finished page!!!');
  }
  
  render() {
    const program = Helpers.get(this.props, 'marketing.program', 'default');
    return (
      <div style={{ width: "100%" }}>
        <Header />
        <div className="Introduction">
          <Card className="Card">
            <div className="CardHeaderText">Application Complete!</div>

            <div className = "ImageContainer">
              <img
                alt="Succesful Submission!"
                src={require("../../assets/ux-skill-check.png")}
                style={{ display: "block", margin: "auto", paddingTop: "0.5em", paddingBottom: "2em", width:'100%' }}
              />
            </div>
            {program.toUpperCase() !== 'UCSD' ? (
            <p className="ParagraphText">
              Thank you for submitting your responses. The Kenzie Academy UX Program
              will be reviewing your application and an Admissions Advisor will
              reach out to you shortly.
              <br /><br />
              Feel free to close this browser tab or window.
            </p>
            ):(
                <p className="ParagraphText">
                Thank you for submitting your responses. The UX Program
              will be reviewing your application and will get back to you. 
                <br /><br />
                Your next step is to schedule time with one of our admissions advisors. <a href = "https://meetings.hubspot.com/lindsay94/uc-san-diego-ux-design-admissions-interview">Click here</a> to schedule a time.
                <br /><br />
                Feel free to close this browser tab or window when you're finished.
              </p> 
            )}
          </Card>
        </div>
      </div>

    );
    }
}

Finished.propTypes = {
  changeRoute: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default pageConnect(Finished);
