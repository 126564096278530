import PropTypes from "prop-types";
import React, { Component } from "react";
import pageConnect from "../../redux/pageConnect";

import Card from "@material-ui/core/Card";

import Header from "../../components/Header";
import NavigationBar from "../../components/NavigationBar";
import NavigationControls from "../../components/NavigationControls";
import Helpers from "../../helpers";
import ValidationError from "./validationError";
import Loading from "../../components/Loading";

let errorItems = {
  errUndef: [],
  applicant: [],
  background: [],
  exercises: {
    appCritique: []
  },
  images: []
};

let errNum = 0;

let errorReturn = (obj, name, subname) => {
  if (obj === undefined) {
    errorItems["errUndef"].push(name);
    errNum++;
  }
  if (obj !== undefined) {
    if (subname) {
      Object.keys(obj[subname]).forEach(x => {
        if (obj[subname][x] === true) {
          errorItems[name][subname].push(x);
          errNum++;
        }
      });
    }
    Object.keys(obj).forEach(x => {
      if (obj[x] === true && x !== 'videoUploaded') {
        errorItems[name].push(x);
        errNum++;
      }
    });
  }
};

class Review extends Component {
  handleNavigation() {
    console.log("Handle Review navigation");
  }

  handleSubmit() {
    errorItems = {
      errUndef: [],
      applicant: [],
      background: [],
      exercises: {
        appCritique: []
      },
      images: []
    };
    const { applicant, background, exercises, images, marketing } = this.props;
    errNum = 0;

    errorReturn(applicant, "applicant");
    errorReturn(background, "background");
    errorReturn(exercises, "exercises", "appCritique");
    errorReturn(images, "images");

    this.props.saveErrorStatus(errNum);

    if (errNum === 0 && !this.props.upload.imagesLoading) {
      //do the submit thang!
      const { program = 'default' } = marketing || {};
      const {
        firstName,
        lastName,
        emailAddress,
        advisor,
        advisorEmail,
      } = this.props.applicant;
      const {
        failTime,
        teamwork,
        teamTroubles,
        experience,
        linkedIn,
        videoName,
        videoSize,
        videoType,
        videoUploaded,
        videoErrored,
        videoUuid,
      } = this.props.background;
      const {
        interestUrl,
        interestReason,
        websiteDoingWell,
        websiteCouldImprove,
        websiteAudience
      } = this.props.exercises.appCritique;

      const data = {
        firstName,
        lastName,
        emailAddress,
        advisor,
        advisorEmail,
        failTime,
        teamwork,
        teamTroubles,
        interestUrl,
        interestReason,
        websiteDoingWell,
        websiteCouldImprove,
        websiteAudience,
        experience,
        linkedIn,
        program,
        videoName,
        videoSize,
        videoType,
        videoUploaded,
        videoErrored,
        videoUuid,
      };

      this.props.submitFinal(data);
    }
  }

  componentDidMount() {
    const { applicant, background, exercises, images } = this.props;

    if (images && applicant && applicant.emailAddress) {
      images.files.forEach(file => {
        this.props.uploadImages(file, applicant.emailAddress);
      });
    }

    if (this.props.error) {
      errorItems = {
        errUndef: [],
        applicant: [],
        background: [],
        exercises: {
          appCritique: []
        },
        images: []
      };
      errNum = 0;
      errorReturn(applicant, "applicant");
      errorReturn(background, "background");
      errorReturn(exercises, "exercises", "appCritique");
      errorReturn(images, "images");
      this.props.saveErrorStatus(errNum);
    }
  }

  componentDidUpdate() {
    const submitted = Helpers.get(this.props, "submission.submitted", false);
    const loading = Helpers.get(this.props, "submission.loading", false);
    const error = Helpers.get(this.props, "submission.error", false);

    if (submitted && !loading && !error) {
      this.props.allowNext();
    }
  }

  render() {
    const imagesLoading = Helpers.get(this.props, "upload.imagesLoading", false);
    const submitLoading = Helpers.get(this.props, "submission.loading", false);

    return (
      <div>
        <Header />
        {(imagesLoading || submitLoading) && <Loading />}
        <div className="Introduction">
          <NavigationBar
            changeRoute={this.props.changeRoute}
            location={this.props.location}
            onNavigation={() => this.handleNavigation()}
          />
          <Card className="Card">
            <div className="CardHeaderText">Review and Submit</div>
            <p className="ParagraphText">
              You’re close to the end! Feel free to visit the previous pages to
              change or finalize any of your responses.
              <br />
              <br />
              Click “Submit” when you’re satisfied with your answers.
            </p>
            <ValidationError errorItems={errorItems} />
            <NavigationControls
              nav={this.props.nav}
              changeRoute={this.props.changeRoute}
              allowNext={this.props.allowNext}
              allowPrev={this.props.allowPrev}
              denyNext={this.props.denyNext}
              denyPrev={this.props.denyPrev}
              location={this.props.location}
              nextLabel="Submit"
              prevLabel="Previous"
              onNextClick={() => this.handleSubmit()}
              onPrevClick={() => this.props.allowPrev()}
            />
          </Card>
        </div>
      </div>
    );
  }
}

Review.propTypes = {
  changeRoute: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default pageConnect(Review);
